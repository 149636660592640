<template>
  <div class="download-page">
    <div class="download-tip">
      <h3>请下载客户端使用该功能</h3>
      <img src="@/assets/pc.png" class="pc-img" alt="">
      <div class="button-wrap">
        <a :href="updateInfo.windows" class="button-item" :class="{'disable': !updateInfo.windows}"  target="_blank">
          <i class="iconfont icon-Windows"></i>Windows 下载
        </a>
        <a :href="updateInfo.mac" class="button-item" :class="{'disable': !updateInfo.mac}"  target="_blank">
          <i class="iconfont icon-mac"></i>Mac 下载
        </a>
        <a v-if="updateInfo.android" :href="updateInfo.android" class="button-item" target="_blank">
          <i class="iconfont icon-android"></i>Android 下载
        </a>
        <a v-if="!updateInfo.android" href="javascript:void(0)" class="button-item disable" @click="showTip">
          <i class="iconfont icon-android"></i>Android 下载
        </a>
      </div>
      <p class="tip">最新版本：{{updateInfo.version}} 更新时间：{{updateInfo.updatetime}}</p>
      <p class="tip" v-if="updateInfo.intro">{{updateInfo.intro}}</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    showTip () {
      this.$message.success('正在开发中，敬请期待。')
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.download-tip {
  text-align: center;
  padding-top: 40px;
  .pc-img {
    width: 300px;
  }
  .button-wrap {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    .button-item {
      padding: 5px 15px;
      border-radius: 2px;
      margin-right: 10px;
      color: $theme-color;
      transition: all ease 0.2s;
      text-decoration: none;
      border: 1px solid $theme-color;
      display: flex;
      align-items: center;
      i {
        font-size: 18px;
        margin-right: 4px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #fff;
        background-color: $theme-color;
      }
      &.disable {
        background-color: #ddd;
        color: #666;
        border: 1px solid #ddd;
      }
    }
  }

  .tip {
    margin-top: 10px;
    text-align: center;
    font-size: 13px;
  }
}
</style>
